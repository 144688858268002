body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f4f5fa;
  width: 100vw;
}

a {
  text-decoration: none !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div {
  font-family: outfit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: white;
}

@font-face {
  font-family: "Outfit";
  src: local("Outfit"), url(./outfit.ttf) format("truetype");
}
